<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<portcalls-form-header :modelPk="modelPk"></portcalls-form-header>
		</pui-form-header>
		<portcalls-modals :modelName="modelName"></portcalls-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#main'">{{ $t('portcalls.maintab') }}</v-tab>
				<v-tab
					v-show="!disabledByShipTransferActive"
					:key="1"
					:href="'#portcallclearancesstatus'"
					v-if="!isCreatingElement && havePermissions(['READ_PORTCALLCLEARANCESSTATUS'])"
					>{{ $t('portcalls.portcallclearancesstatus') }}</v-tab
				>
				<v-tab v-show="!disabledByShipTransferActive" :key="2" :href="'#mships'" v-if="!isCreatingElement">{{ $t('portcalls.mship') }}</v-tab>
				<v-tab v-show="!disabledByShipTransferActive" :key="3" :href="'#portcallberths'" v-if="!isCreatingElement">{{
					$t('portcalls.portcallberths')
				}}</v-tab>
				<v-tab
					v-show="!disabledByShipTransferActive"
					:key="4"
					:href="'#imofalformsgeneral'"
					v-if="
						!isCreatingElement &&
						!isModalDialog &&
						havePermissions(['READ_IMOFALFORMS']) &&
						!this.$store.getters.getSession.profiles.includes('MTA')
					"
					>{{ $t('grid.imofalformsgeneral.tab') }}</v-tab
				>

				<v-tab
					:key="5"
					v-show="!disabledByShipTransferActive"
					:href="'#securityinformation'"
					v-if="!isCreatingElement && !isModalDialog && havePermissions(['READ_SECURITYFORMS'])"
					>{{ $t('portcalls.securityinformation') }}</v-tab
				>

				<v-tab
					:key="6"
					v-show="!disabledByShipTransferActive"
					:href="'#wastenotification'"
					v-if="!isCreatingElement && !isModalDialog && havePermissions(['READ_WASTENOTIFICATIONS'])"
					>{{ $t('portcalls.wastenotification') }}</v-tab
				>

				<v-tab
					:key="7"
					v-show="!disabledByShipTransferActive"
					:href="'#mdhealt'"
					v-if="!isCreatingElement && !isModalDialog && havePermissions(['READ_MDHEALT'])"
					>{{ $t('portcalls.mdhealt') }}</v-tab
				>
				<v-tab :key="8" v-show="!disabledByShipTransferActive" :href="'#tab_documents'" v-if="!isCreatingElement && !isModalDialog">{{
					$t('documentextrafields.tabportcalls')
				}}</v-tab>
				<v-tab
					:key="9"
					v-show="!disabledByShipTransferActive"
					:href="'#emergency'"
					v-if="model.emergencyProcess && !isCreatingElement && !isModalDialog && havePermissions(['READ_EMERGENCYPROCESS'])"
					>{{ $t('portcalls.emergency') }}</v-tab
				>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="'main'">
					<v-row class="pui-form-layout">
						<v-col
							:class="
								isCreatingElement
									? 'cols-8 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8'
									: 'cols-6 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'
							"
						>
							<!--
						<v-col :cols="isCreatingElement ? '8' : '6'">
							-->
							<pui-field-set :title="$t('portcalls.generalinfo')">
								<v-row dense v-if="!isCreatingElement">
									<v-flex xs12 md12 lg6 x6>
										<pui-date-field
											:id="`requestdate-${modelName}`"
											:label="$t('portcalls.requestdate2')"
											v-model="model.requestdate"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											toplabel
											required
											time
										></pui-date-field>
									</v-flex>
									<v-flex xs12 md12 lg6 x6></v-flex>
								</v-row>
								<v-row dense>
									<v-flex xs12 md12 lg6 x6>
										<pui-select
											id="portcode"
											attach="portcode"
											:label="this.$t('portcalls.portcode')"
											toplabel
											v-model="model"
											modelName="mlocalports"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											reactive
											clearable
											required
											:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
											:itemsToSelect="itemsToSelectPortcode"
											:itemValue="['countrycode', 'portcode']"
											:itemText="(item) => `${item.countrycode}${item.portcode} - ${item.portdescription}`"
											:order="{ locode: 'asc' }"
										></pui-select>
									</v-flex>
									<v-flex xs12 md12 lg6 x6>
										<pui-select
											:id="`shipid-${modelName}`"
											:attach="`shipid-${modelName}`"
											:label="$t('portcalls.shipid')"
											toplabel
											clearable
											reactive
											required
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											v-model="model"
											modelName="mships"
											:itemsToSelect="itemsToSelectShipid"
											:modelFormMapping="{ shipid: 'shipid', version: 'shipversion' }"
											:itemValue="['shipid', 'version']"
											:itemText="(item) => `${item.imo} - ${item.shipname}`"
											:order="{ shipname: 'asc' }"
											:detailModelName="`mships`"
											:detailComponentName="`mships-form`"
											:detailActions="{ update: false, read: true, create: true }"
											showOnlyActives
											:fixedFilter="filteractiveShips"
										></pui-select>
									</v-flex>
								</v-row>
								<v-layout>
									<v-flex xs12>
										<v-layout wrap>
											<v-flex xs12 md12 lg6 x6 v-if="isCreatingElement">
												<pui-select
													:id="`shipagendid-${modelName}`"
													:attach="`shipagendid-${modelName}`"
													:label="$t('portcalls.shipagendid')"
													toplabel
													clearable
													:disabled="
														!isCreatingElement ||
														!this.model.countrycode ||
														!this.model.portcode ||
														this.rol != 'SHAG' ||
														disabledByShipTransferActive
													"
													:order="{ nombre: 'asc' }"
													reactive
													required
													v-model="model"
													:items="consigneeList"
													:itemsToSelect="itemsToSelectShipagendid"
													:modelFormMapping="{ codorg: 'shipagendid', descripcion: 'shipagendname' }"
													:itemValue="['codorg', 'descripcion']"
													itemText="label"
													:key="consigneeKey"
												>
												</pui-select>
											</v-flex>
											<v-flex xs12 md12 lg6 x6 v-if="!isCreatingElement">
												<pui-text-field
													:id="`shipagendid-${modelName}`"
													:attach="`shipagendid-${modelName}`"
													:label="$t('portcalls.shipagendid')"
													toplabel
													clearable
													:disabled="
														!isCreatingElement ||
														!this.model.countrycode ||
														!this.model.portcode ||
														this.rol != 'SHAG' ||
														disabledByShipTransferActive
													"
													:order="{ nombre: 'asc' }"
													reactive
													required
													maxlength="1000"
													v-model="model.shipagendname"
												>
												</pui-text-field>
											</v-flex>
											<v-flex xs12 md12 lg6 x6>
												<pui-select
													:label="$t('portcalls.cargoagent')"
													v-model="model.org"
													:disabled="
														!isCreatingElement &&
														(this.rol != 'SHAG' ||
															this.model.portcallstatus == 0 ||
															this.model.portcallstatus == 3 ||
															disabledByShipTransferActive)
													"
													toplabel
													clearable
													:itemsToSelect="model.org"
													return-object
													:items="consigneeListCargo"
													itemValue="codorg"
													itemText="descripcion"
													multiple
													:order="{ descripcion: 'asc' }"
													:key="consigneeKey"
												></pui-select>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
								<v-row dense>
									<v-flex xs12 md12 lg6 x6 v-if="isCreatingElement">
										<pui-date-field
											:id="`eta-${modelName}`"
											:label="$t('portcalls.eta2')"
											v-model="model.eta"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											toplabel
											required
											time
											:min="isCreatingElement ? new Date() : null"
											:max="model.etd"
										></pui-date-field>
									</v-flex>
									<v-flex xs12 md12 lg6 x6 v-if="isCreatingElement">
										<pui-date-field
											:id="`etd-${modelName}`"
											:label="$t('portcalls.etd2')"
											v-model="model.etd"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											toplabel
											required
											time
											:min="model.eta"
										></pui-date-field>
									</v-flex>
								</v-row>
								<v-layout>
									<v-flex xs12>
										<v-layout wrap>
											<v-flex xs12 md12 lg6 x6>
												<pui-select
													:id="`previousport-${modelName}`"
													:attach="`previousport-${modelName}`"
													:label="$t('portcalls.previousport')"
													toplabel
													clearable
													required
													:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
													v-model="model"
													modelName="mports"
													:modelFormMapping="{ portcode: 'previousport', countrycode: 'previouscountry' }"
													:itemsToSelect="itemsToSelectPreviousport"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													reactive
												></pui-select>
											</v-flex>

											<v-flex xs12 md12 lg6 x6>
												<pui-select
													:id="`nextport-${modelName}`"
													:attach="`nextport-${modelName}`"
													:label="$t('portcalls.nextport')"
													toplabel
													clearable
													:disabled="
														(!isCreatingElement && (this.model.portcallstatus == 0 || this.model.portcallstatus == 3)) ||
														this.rol != 'SHAG' ||
														disabledByShipTransferActive
													"
													v-model="model"
													modelName="mports"
													:modelFormMapping="{ portcode: 'nextport', countrycode: 'nextcountry' }"
													:itemsToSelect="itemsToSelectNextport"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													reactive
												></pui-select>
											</v-flex>
											<v-flex xs12 md12 lg6 x6 v-if="!isCreatingElement"> </v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
								<v-layout>
									<v-flex xs12 md12 lg6 x6>
										<pui-select
											:id="`lastloadingport-${modelName}`"
											:attach="`lastloadingport-${modelName}`"
											:label="$t('portcalls.lastloadingport')"
											toplabel
											clearable
											v-model="model"
											modelName="mports"
											:modelFormMapping="{ portcode: 'lastloadingport', countrycode: 'lastloadingcountry' }"
											:itemsToSelect="itemsToSelectLastport"
											:itemValue="['countrycode', 'portcode']"
											:itemText="(item) => `${item.locode} - ${item.portdescription}`"
											reactive
											:fixedFilter="filterPortsLast"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
										></pui-select>
									</v-flex>
									<v-flex xs12 md12 lg6 x6>
										<pui-text-field
											:id="`transitcargo-${modelName}`"
											:label="$t('portcalls.transitcargo')"
											v-model="model.transitcargo"
											toplabel
											placeholder="Name and Quantity (e.g. 100 x TEU container)"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout>
									<v-flex xs12>
										<v-layout wrap>
											<v-flex xs12 md12 lg6 x6>
												<pui-text-field
													:id="`year-${modelName}`"
													:label="$t('portcalls.mastername')"
													v-model="model.mastername"
													required
													toplabel
													:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												></pui-text-field>
											</v-flex>
											<v-flex xs12 md12 lg6 x6>
												<pui-text-field
													:id="`year-${modelName}`"
													:label="$t('portcalls.voyagenum')"
													v-model="model.voyagenum"
													toplabel
													required
													:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												></pui-text-field>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>

								<v-row dense>
									<v-flex xs12 md12 lg6 x6>
										<pui-number-field
											:id="`arrdraftfwd-${modelName}`"
											v-model="model.arrdraftfwd"
											:label="$t('portcalls.arrdraftfwd')"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											toplabel
											required
											suffix="m"
											decimals="2"
											min="1"
											max="99999.99"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md12 lg6 x6>
										<pui-number-field
											:id="`arrdraftaft-${modelName}`"
											v-model="model.arrdraftaft"
											:label="$t('portcalls.arrdraftaft')"
											:disabled="
												!isCreatingElement ||
												this.rol != 'SHAG' ||
												this.model.portcallstatus == 0 ||
												this.model.portcallstatus == 3 ||
												disabledByShipTransferActive
											"
											toplabel
											required
											suffix="m"
											decimals="2"
											min="1"
										></pui-number-field>
									</v-flex>
								</v-row>
								<v-row dense>
									<v-flex xs12 md12 lg6 x6>
										<pui-number-field
											:id="`depdraftfwd-${modelName}`"
											v-model="model.depdraftfwd"
											:label="$t('portcalls.depdraftfwd')"
											:disabled="
												this.rol != 'SHAG' ||
												this.model.portcallstatus == 0 ||
												this.model.portcallstatus == 3 ||
												disabledByShipTransferActive
											"
											toplabel
											suffix="m"
											max="99999"
											decimals="2"
											min="1"
										></pui-number-field>
									</v-flex>

									<v-flex xs12 md12 lg6 x6>
										<pui-number-field
											:id="` depdraftaft-${modelName}`"
											v-model="model.depdraftaft"
											:label="$t('portcalls.depdraftaft')"
											:disabled="
												this.rol != 'SHAG' ||
												this.model.portcallstatus == 0 ||
												this.model.portcallstatus == 3 ||
												disabledByShipTransferActive
											"
											toplabel
											suffix="m"
											max="99999"
											decimals="2"
											min="1"
										></pui-number-field>
									</v-flex>
								</v-row>
								<v-row dense>
									<v-flex xs12 md12 lg12 x12>
										<pui-select
											:label="$t('portcalls.fuelsufficientind')"
											itemText="text"
											itemValue="value"
											:disabled="
												(!isCreatingElement && this.model.portcallstatus != 1) ||
												this.rol != 'SHAG' ||
												disabledByShipTransferActive
											"
											toplabel
											v-model="model"
											clearable
											reactive
											:items="codes"
											:itemsToSelect="quantityItemsToSelect"
											:modelFormMapping="{ value: 'fuelsufficientind' }"
										></pui-select>
									</v-flex>
								</v-row>

								<v-row dense>
									<v-flex xs12 md12 lg12 x12>
										<pui-text-area
											:id="`damages-${modelName}`"
											v-model="model.damages"
											:label="$t('portcalls.damages')"
											:disabled="
												(!isCreatingElement && this.model.portcallstatus != 1) ||
												this.rol != 'SHAG' ||
												disabledByShipTransferActive
											"
											toplabel
											maxlength="256"
										></pui-text-area>
									</v-flex>
								</v-row>
								<v-row dense>
									<v-flex xs12 md12 lg12 x12>
										<pui-text-area
											:id="`incidents-${modelName}`"
											v-model="model.incidents"
											:label="$t('portcalls.incidents')"
											:disabled="
												(!isCreatingElement && this.model.portcallstatus != 1) ||
												this.rol != 'SHAG' ||
												disabledByShipTransferActive
											"
											toplabel
											maxlength="256"
										></pui-text-area>
									</v-flex>
								</v-row>
								<v-row dense>
									<v-flex xs12 md12 lg12 x12>
										<pui-text-area
											:id="`observations-${modelName}`"
											v-model="model.observations"
											:label="$t('portcalls.observations')"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											toplabel
											maxlength="256"
										></pui-text-area>
									</v-flex>
								</v-row>
								<v-row>
									<v-flex xs12 md12 lg12 x12>
										<pui-text-area
											v-if="
												this.model.rejectionreason != null &&
												this.model.portcallstatus == 6 &&
												this.model.rejectionreason != ''
											"
											:id="`rejectionreason-${modelName}`"
											v-model="model.rejectionreason"
											:label="$t('portcalls.rejectionreason')"
											disabled
											toplabel
											maxlength="256"
										></pui-text-area>
									</v-flex>
								</v-row>
							</pui-field-set>
						</v-col>
						<v-col v-if="isCreatingElement" class="cols-4 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
							<pui-field-set :title="$t('portcalls.firststop')" v-if="isCreatingElement">
								<v-layout wrap>
									<v-flex xs12 md12 lg12 x12 v-if="isCreatingElement">
										<pui-select
											:id="`portfacilitygisiscode-${modelName}`"
											:attach="`portfacilitygisiscode-${modelName}`"
											:label="$t('portcalls.portfacilitygisiscode')"
											toplabel
											v-model="model"
											modelName="vlupmportfacilities"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											required
											:modelFormMapping="{
												portfacilitygisiscode: 'portfacilitygisiscode'
											}"
											:itemsToSelect="itemsToSelectPortFacilities"
											:itemValue="['portfacilitygisiscode']"
											:itemText="
												(item) =>
													`${item.countrycode}${item.portcode}-${item.portfacilitygisiscode} - ${item.portfacilityname}`
											"
											:order="{ portfacilitygisiscode: 'asc' }"
											:fixedFilter="filterCountry"
											reactive
										></pui-select>
									</v-flex>
									<v-flex xs12 md12 lg12 x12>
										<pui-select
											:id="`reqberthingtypeid-${modelName}`"
											:attach="`reqberthingtypeid-${modelName}`"
											:label="$t('portcalls.reqberthingtypeid')"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											toplabel
											clearable
											required
											v-model="model"
											modelName="mberthingtypes"
											:itemsToSelect="itemsToSelectReqberthingtypeid"
											:modelFormMapping="{ berthingtypeid: 'reqberthingtypeid' }"
											itemValue="berthingtypeid"
											itemText="typedesc"
											:order="{ typedesc: 'asc' }"
											reactive
										></pui-select>
									</v-flex>
									<v-flex xs12 md12 lg12 x12>
										<pui-select
											:id="`reqpurposecode-${modelName}`"
											:attach="`reqpurposecode-${modelName}`"
											:label="$t('portcalls.reqpurposecode')"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											toplabel
											clearable
											required
											v-model="model"
											modelName="mportcallpurposes"
											:itemsToSelect="itemsToSelectReqpurposecode"
											:modelFormMapping="{ portcallpurposecode: 'portcallpurposecode' }"
											itemValue="portcallpurposecode"
											itemText="purposedesc"
											:order="{ purposedesc: 'asc' }"
											reactive
										></pui-select>
									</v-flex>
									<v-flex xs12 md12 lg12 x12>
										<pui-select
											:id="`portfacilityberthoperators-${modelName}`"
											:attach="`portfacilityberthoperators-${modelName}`"
											:label="$t('portcalls.portfacilityberthoperators')"
											:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
											toplabel
											clearable
											required
											v-model="model"
											modelName="vlupportfacilityberthoperators"
											:itemsToSelect="itemsToSelectportfacilityberthoperators"
											:modelFormMapping="{ portfacilitybeopid: 'portfacilitybeopid' }"
											itemValue="portfacilitybeopid"
											itemText="berthoperatorname"
											:order="{ berthoperatorname: 'asc' }"
											:fixedFilter="filterPortFacility"
											reactive
										></pui-select>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-col>
						<v-col v-if="!isCreatingElement" col="5">
							<pui-field-set :title="$t('portcalls.date')">
								<v-layout wrap>
									<v-row dense>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`eta-${modelName}`"
												:label="$t('portcalls.eta2')"
												v-model="model.eta"
												:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												toplabel
												required
												time
											></pui-date-field>
										</v-flex>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`etd-${modelName}`"
												:label="$t('portcalls.etd2')"
												v-model="model.etd"
												:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												toplabel
												required
												time
											></pui-date-field>
										</v-flex>
									</v-row>
									<v-row dense>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`ata-${modelName}`"
												:label="$t('portcalls.ata2')"
												v-model="model.ata"
												:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												toplabel
												time
											></pui-date-field>
										</v-flex>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`atd-${modelName}`"
												:label="$t('portcalls.atd2')"
												v-model="model.atd"
												:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												toplabel
												time
											></pui-date-field>
										</v-flex>
									</v-row>
								</v-layout>
							</pui-field-set>
							<pui-field-set :title="$t('portcalls.anchorage')">
								<v-layout wrap>
									<v-row dense>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`arrivalata-${modelName}`"
												:label="$t('portcalls.arribalATA')"
												v-model="model.arrataanchorage"
												:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												toplabel
												time
											></pui-date-field>
										</v-flex>

										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`arrivalatd-${modelName}`"
												:label="$t('portcalls.arrivalATD')"
												v-model="model.arratdanchorage"
												:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												toplabel
												time
											></pui-date-field>
										</v-flex>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`departureata-${modelName}`"
												:label="$t('portcalls.depatureATA')"
												v-model="model.depataanchorage"
												:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												toplabel
												time
											></pui-date-field>
										</v-flex>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`depatureatd-${modelName}`"
												:label="$t('portcalls.departureATD')"
												v-model="model.depatdanchorage"
												:disabled="!isCreatingElement || this.rol != 'SHAG' || disabledByShipTransferActive"
												toplabel
												time
											></pui-date-field>
										</v-flex>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`departureata-${modelName}`"
												:label="$t('portcalls.emeataanchorage')"
												v-model="model.emeataanchorage"
												disabled
												toplabel
												time
											></pui-date-field>
										</v-flex>
										<v-flex xs12 md12 lg6 x6>
											<pui-date-field
												:id="`depatureatd-${modelName}`"
												:label="$t('portcalls.emeatdanchorage')"
												v-model="model.emeatdanchorage"
												disabled
												toplabel
												time
											></pui-date-field>
										</v-flex>
									</v-row>
								</v-layout>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="'portcallclearancesstatus'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="portcallclearancesstatus-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcallid: 'portcallid' }"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="3" :value="'portcallberths'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="portcallberths-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcallid: 'portcallid' }"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="'mships'" lazy v-if="!isCreatingElement">
					<shiptecnico-form :value="model"></shiptecnico-form>
				</v-tab-item>
				<v-tab-item :key="4" :value="'imofalformsgeneral'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="imofalformsgeneral-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcallid: 'portcallid' }"
						:formDisabled="true"
						:readOnly="true"
						:showDeleteBtn="showbotons && this.rol == 'SHAG'"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="5" :value="'securityinformation'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="securityforms-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcallid: 'portcallid' }"
						:showCreateBtn="showbotons"
						:showDeleteBtn="showbotons"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="6" :value="'wastenotification'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="wastenotifications-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcallid: 'portcallid' }"
						:showCreateBtn="showbotons"
						:showDeleteBtn="showbotons"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="7" :value="'mdhealt'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="mdhealt-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcallid: 'portcallid' }"
						:showCreateBtn="showbotons"
						:showDeleteBtn="showbotons"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="8" :value="'tab_documents'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="documentextrafieldsportcall-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:formDisabled="this.model.portcallstatus == 0"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="9" :value="'emergency'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="emergencyprocess-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcallid: 'portcallid' }"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="((this.model.portcallstatus == 3 || this.model.portcallstatus == 0) && !isCreatingElement) || this.rol != 'SHAG'"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import portcallsActions from './PortcallsActions';
import portcallsModals from './PortcallsModals.vue';

export default {
	name: 'portcalls-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		portcallsModals: portcallsModals
	},
	data() {
		return {
			modelName: 'portcalls',
			actions: portcallsActions.formactions,
			visitidB64: null,
			tabmodel: 'main',
			rol: null,
			codes: [
				{
					text: this.$t('portcalls.si'),
					value: '0'
				},
				{
					text: this.$t('portcalls.no'),
					value: '1'
				}
			],
			consigneeList: [],
			consigneeListCargo: [],
			consigneeKey: 0,
			orgs: null,
			modelshipid: null,
			modelshipversion: null,
			disabledByShipTransferActive: false,
			showbotons: true
		};
	},
	methods: {
		afterGetData() {
			if (localStorage.getItem('tabFromAction')) {
				this.tabmodel = localStorage.getItem('tabFromAction');
				localStorage.setItem('tabFromAction', null);
			}
			if (this.model.portcallstatus == 0 || this.model.portcallstatus == 3) {
				this.showbotons = false;
			}

			this.getTransferData();
			const jsonVisitid = '{"visitdid":"' + this.model.visitid + '"}';
			this.visitidB64 = this.$puiUtils.utf8ToB64(jsonVisitid);
			this.orgs = this.model.org;
			this.getConsignee();
			this.lastshipEgencyTranfersCode();
			const optsEvents = {
				model: 'mships',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'shipid',
							op: 'eq',
							data: this.model.shipid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', optsEvents, (response) => {
				if (response.data.data) {
					this.model.shipid = response.data.data[0].shipid;
					this.model.shipversion = response.data.data[0].version;
				}
			});
		},
		getTransferData() {
			let admin = 0;
			for (let i = 0; i < this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST.length; i++) {
				if (
					this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST[i] == 'ALL' ||
					this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST[i] == 'ADMI'
				) {
					admin++;
				}
			}

			if (admin == 0) {
				let org = this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_ORG_ID_CODE;

				const opts = {
					model: 'shipagencytransfers',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{ field: 'portcallid', op: 'eq', data: this.model.portcallid },
							{ field: 'statuscode', op: 'eq', data: 'P' },
							{ field: 'isactive', op: 'eq', data: '1' },
							{ field: 'shipagentidrecipient', op: 'eq', data: org }
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data.data.length == 1) {
						this.disabledByShipTransferActive = true;
					}
				});
			}
		},
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados

			return permissions.every((permission) => allowedForms.includes(permission));
		},
		getConsignee() {
			if (this.model.countrycode || this.model.portcode) {
				const urlEquipmentServices = '/gestaut/getUserConsignee';
				const params = {
					countrycode: this.model.countrycode,
					portcode: this.model.portcode
				};
				this.$puiRequests.getRequest(urlEquipmentServices, params, (response) => this.setConsignee(response.data));

				const urlEquipmentServices2 = '/gestaut/getORGConsigneeCargoAgent';
				const params2 = {
					countrycode: this.model.countrycode,
					portcode: this.model.portcode
				};
				this.$puiRequests.getRequest(urlEquipmentServices2, params2, (response) => {
					this.setConsigneeCargo(response.data);
				});
			}
		},
		setConsignee(responseConsignee = []) {
			this.consigneeList = [];
			this.consigneeList = responseConsignee.map((c) => {
				let orgs = this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS;
				for (let i = 0; i < orgs.length; i++) {
					if (orgs[i] == c.codorg) {
						return {
							codorg: c.codorg,
							descripcion: c.descripcion,
							label: c.descripcion
						};
					}
				}
			});
			this.consigneeKey++;
		},
		setConsigneeCargo(responseConsignee = []) {
			this.consigneeListCargo = [];
			this.consigneeListCargo = responseConsignee.map((c) => ({
				codorg: c.codorg,
				descripcion: c.descripcion,
				label: c.descripcion
			}));

			this.consigneeKey++;

			this.model.org = this.orgs;
		},
		lastshipEgencyTranfersCode() {
			if (this.model.shipAgencyTransfers != null) {
				const transfers = this.model.shipAgencyTransfers;
				if (transfers.length > 0) {
					const lastTransfer = transfers[transfers.length - 1];
					this.lastTransferStatusCode = lastTransfer.statuscode;
				}
			}
		}
	},
	mounted() {
		for (let i = 0; i < this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST.length; i++) {
			if (this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_TYPE_CODE_LIST[i] == 'SHAG') this.rol = 'SHAG';
		}
	},
	computed: {
		itemsToSelectPortFacilities() {
			return [{ portfacilitygisiscode: this.model.portfacilitygisiscode }];
		},
		itemsToSelectPortcode() {
			return [{ countrycode: this.model.countrycode, portcode: this.model.portcode }];
		},
		itemsToSelectShipagendid() {
			return [{ codorg: this.model.shipagendid, descripcion: this.model.shipagendname }];
		},
		itemsToSelectShipid() {
			return [{ shipid: this.model.shipid, version: this.model.shipversion }];
		},
		itemsToSelectPreviousport() {
			return [{ countrycode: this.model.previouscountry, portcode: this.model.previousport }];
		},
		itemsToSelectNextport() {
			return [{ countrycode: this.model.nextcountry, portcode: this.model.nextport }];
		},
		itemsToSelectLastport() {
			return [{ countrycode: this.model.lastloadingcountry, portcode: this.model.lastloadingport }];
		},
		itemsToSelectReqberthingtypeid() {
			return [{ berthingtypeid: this.model.reqberthingtypeid }];
		},
		itemsToSelectReqpurposecode() {
			// NMSWG-1517. Se cambia la propiedad del modelo
			return [{ portcallpurposecode: this.model.portcallpurposecode }];
		},
		itemsToSelectportfacilityberthoperators() {
			return [{ portfacilitybeopid: this.model.portfacilitybeopid }];
		},
		filterLocalPorts() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'countrycode', op: 'eq', data: 'GE' }]
					}
				]
			};
		},
		filterCountry() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'countrycode', op: 'eq', data: 'GE' },
							{ field: 'portcode', op: 'eq', data: this.model.portcode }
						]
					}
				]
			};
		},
		filteractiveShips() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'inactive_ind', op: 'eq', data: 'No' }]
					}
				]
			};
		},
		filterPortsLast() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'portcode', op: 'ne', data: this.model.portcode }]
					}
				]
			};
		},
		filterPortFacility() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'countrycode', op: 'eq', data: this.model.countrycode },
							{ field: 'portcode', op: 'eq', data: this.model.portcode },
							{ field: 'portfacilitygisiscode', op: 'eq', data: this.model.portfacilitygisiscode }
						]
					}
				]
			};
		},
		quantityItemsToSelect() {
			return [{ value: this.model.fuelsufficientind }];
		}
	},
	watch: {
		'model.portcode'() {
			if (this.model.countrycode || this.model.portcode) {
				this.getConsignee();
				this.model.reqdockid = 6;
				this.model.reqberthingtypeid = 21;
			}
		}
	}
};
</script>
